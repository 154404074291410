<template>
    <section class="s-banner">
        <div class="s-banner-container a-right">
            <div class="s-banner-text" data-effect="block">
                <h3>{{item.small_description}}</h3>
                <h2>{{item.heading}}</h2>
                <p>{{item.description}}</p>
                <a class="f-bp" :href="'#/page/'+item.link_section">
                    <svg class="f-bp-arrow" viewBox="0 0 6 10">
                        <path
                            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                    </svg>
                    <i class="f-bp-label">About us</i>
                    <svg class="f-bp-arrow" viewBox="0 0 6 10">
                        <path
                            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                    </svg>
                </a>
            </div>
            <div class="s-banner-figure" v-if="item.data">
                <div class="s-banner-src" id="s-banner-src2"> <img :src="$store.state.file_url + item.data[1].banner[0].src + '?alt=media'" id="s-banner-src2-img" alt="cutting gold cotton with large scissors"> </div>
                <div class="s-banner-src"> <img :src="$store.state.file_url + item.data[0].banner[0].src + '?alt=media'" alt="detail shot from rino pelle badge on jacket"> </div>
            </div>
            <div class="s-banner-background" data-background="#f3f2f5"></div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["item"],
    data(){
        return{

        }
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll);
        if(document.getElementById('s-banner-src2')){
            document.getElementById('s-banner-src2').style.transform = 'matrix(1, 0, 0, 1, 0, 49.0244)'
        }
    },
    methods:{
        handleScroll(){
            if(document.getElementById('s-banner-src2')){
                document.getElementById('s-banner-src2').style.transform = 'matrix(1, 0, 0, 1, 0, '+(window.scrollY/60)+')'
            }
            if(document.getElementById('s-banner-src2-img')){
                document.getElementById('s-banner-src2-img').style.transform = 'scale('+window.scrollY/3000+')';
            }
        }
    }
}
</script>

<style>
.s-banner h3{
    display: block;
    text-align: start;
    position: relative;
    transform-origin: 0px 0px;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    opacity: 0.8;
    font-weight: bold;
    font-size: 0.688vw;
    line-height: 0.688vw;
    margin-bottom: 3.75vw;
    letter-spacing: 0.188vw;
    text-transform: uppercase;
    font-family: 'Aktiv Grotesk';
}
.s-banner h2{
    font-weight: 300;
    font-size: 4.188vw;
    line-height: 4.5vw;
    margin-bottom: 3vw;
    letter-spacing: -0.179vw;
    font-family: 'Saol Display';
}
.s-banner p{
    opacity: 0.8;
    font-size: 0.938vw;
    font-weight: normal;
    line-height: 1.875vw;
    letter-spacing: 0.034vw;
    font-family: 'Aktiv Grotesk';
}
.s-banner-text p{
    width: calc(100vw / 15 * 3);
}
.s-header-text{
    display: flex;
    height: 50vh;
    align-items: flex-end;
}
.s-header-scroll{
    bottom: 1vw;
}
@media only screen and (max-width: 580px){
    .s-banner h3{
        font-size: 2.778vw !important;
        line-height: 4.167vw !important;
        margin-bottom: 5.556vw !important;
        letter-spacing: 0.75vw !important;
    }
    .s-banner h2{
        font-size: 11.111vw !important;
        line-height: 11.944vw !important;
        margin-bottom: 8.333vw !important;
        letter-spacing: -0.417vw !important;
    }
    .s-banner p{
        width: calc(100vw / 12 * 8) !important;
        font-size: 4.167vw !important;
        line-height: 7.5vw !important;
        letter-spacing: 0.15vw !important;
    }
    .s-banner-text p {
        width: calc(100vw / 4 * 2);
    }
    .s-banner-container.a-left .s-banner-src:nth-child(1) {
        left: 0;
        top: 19vw;
    }
    .s-carousel-slide:nth-child(3n+2) .s-carousel-item:nth-child(3n+3){
        margin-left: 0;
    }
}
</style>